import React from "react";
import { Header, Divider, Button, Grid, Modal } from "semantic-ui-react";

const ThreadDetailsModal = ({
  thread,
  onClose,
  onOpen,
  open,
  inventoryForm,
  isDarkMode,
}) => {
  return thread ? (
    <Modal
      basic={isDarkMode}
      closeIcon
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      dimmer
    >
      <Modal.Header>{thread.title}</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <Grid>
            <Grid.Row>
              <Grid.Column width={5}>
                <img alt={thread.altText} src={thread.imgSrc} />
              </Grid.Column>
              <Grid.Column width={11}>
                <Header as="h3" inverted={isDarkMode}>
                  Inventory
                </Header>
                <div style={{ margin: "10px auto" }}>{inventoryForm}</div>
                <Divider />
                <div>
                  <Header as="h3" inverted={isDarkMode}>
                    Type
                  </Header>
                  <Button circular size="small" color="olive">
                    {thread.type}
                  </Button>
                </div>
                <Divider />
                <div>
                  <Header as="h3" inverted={isDarkMode}>
                    Tags
                  </Header>
                  {thread.tags.split(",").map((t) => (
                    <Button
                      style={{ margin: "3px" }}
                      key={t}
                      circular
                      size="tiny"
                      color="orange"
                    >
                      {t}
                    </Button>
                  ))}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  ) : (
    <noscript />
  );
};

export default ThreadDetailsModal;
