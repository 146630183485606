import React from "react";
import { Button, Card } from "semantic-ui-react";
import InventoryForm from "./InventoryForm";
import { PAGE_SIZE } from "../constants";
import ThreadDetailsModal from "./ThreadDetailsModal";
import ThreadCard from "./ThreadCard";
import useWindowDimensions from "../utils/hooks/useWindowDimensions";

function setItemsPerRow(width, length) {
  if (length < 8) return length || 1;
  if (width > 650) return 8;
  return 4;
}

const MainTable = ({
  userInv,
  data,
  page,
  setPage,
  firebase,
  setOpen,
  currentUser,
  setRefetchInv,
  isDarkMode,
  selectedOptions,
  searchTerm,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(true);
  const [selectedThread, setSelectedThread] = React.useState(data[0]);
  const { width } = useWindowDimensions();
  const invForm = currentUser ? (
    selectedThread && (
      <InventoryForm
        isDarkMode={isDarkMode}
        setRefetchInv={setRefetchInv}
        userInv={userInv?.[selectedThread.id]}
        thread={selectedThread}
        currentUser={currentUser}
        firebase={firebase}
      />
    )
  ) : (
    <Button.Group>
      <Button onClick={() => setOpen(true)}>
        Sign in to track your inventory
      </Button>
      <Button.Or />
      <Button primary as="a" href={selectedThread?.sizes[0]?.url}>
        Order more
      </Button>
    </Button.Group>
  );
  return (
    <div style={{ padding: "20px" }}>
      <ThreadDetailsModal
        isDarkMode={isDarkMode}
        onClose={() => setIsModalOpen(false)}
        onOpen={() => setIsModalOpen(true)}
        open={isModalOpen}
        thread={selectedThread}
        inventoryForm={invForm}
      />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Card.Group itemsPerRow={setItemsPerRow(width, data.length)}>
          {data.map((d, i) => {
            return (
              <ThreadCard
                key={i}
                onClick={() => {
                  setSelectedThread(d);
                  setIsModalOpen(true);
                }}
                isDarkMode={isDarkMode}
                thread={d}
              />
            );
          })}
        </Card.Group>
      </div>
      {page * PAGE_SIZE <= data.length && (
        <Button
          color="green"
          size="huge"
          fluid
          style={{ marginTop: "20px" }}
          onClick={() => setPage(page + 1)}
        >
          Show more
        </Button>
      )}
    </div>
  );
};

export default MainTable;
