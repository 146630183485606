import axios from "axios";
import classNames from "classnames";
import React from "react";
import MainTable from "./components/MainTable";
import { PAGE_SIZE } from "./constants";
import Navbar from "./components/Navbar";
import Login from "./components/Login";
import Logo from "./components/Logo";
import {
  Dropdown,
  Input,
  Button,
  Divider,
  Modal,
  Header,
  Segment,
  Radio,
  Form,
} from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import firebase from "firebase/app";
import "firebase/auth"; // for authentication
import "firebase/analytics"; // for analytics
import "firebase/storage"; // for storage
import "firebase/database"; // for realtime database
import "firebase/firestore"; // for cloud firestore
import "firebase/messaging"; // for cloud messaging
import "firebase/functions"; // for cloud functions

import "semantic-ui-css/semantic.min.css";
import "./index.css";

const config = {
  apiKey: "AIzaSyBO0kIPqOxpZ4bRYhgWo1zfIgXqZ-HRrSQ",
  authDomain: "threadtracker2.firebaseapp.com",
  databaseURL: "https://threadtracker2-default-rtdb.firebaseio.com",
  projectId: "threadtracker2",
  storageBucket: "threadtracker2.appspot.com",
  messagingSenderId: "103389525263",
  appId: "1:103389525263:web:e556186689ddeea147fa7a",
  measurementId: "G-8GSGZQ44ST",
};

firebase.initializeApp(config);
firebase.analytics();

function App() {
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState(null);
  const [inv, setInv] = React.useState(null);
  const [subset, setSubset] = React.useState([]);
  const [showOwned, setShowOwned] = React.useState(false);
  const [refetchInv, setRefetchInv] = React.useState(true);
  const [isDarkMode, setIsDarkMode] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [localSearch, setLocalSearch] = React.useState("");

  React.useEffect(() => {
    axios.get(`${config.databaseURL}/threads.json`).then((r) => {
      setData(r.data);
      setOptions(
        Array.from(new Set(r.data.map((d) => d.type))).map((d) => ({
          value: d,
          text: d,
          key: d,
        }))
      );
    });
  }, []);

  firebase.auth().onAuthStateChanged((user) => {
    if (user) setCurrentUser(user);
  });

  React.useEffect(() => {
    if (searchTerm) return;
    if (currentUser?.uid) {
      if (refetchInv) setRefetchInv(false);
      firebase
        .database()
        .ref(`/users/${currentUser?.uid}/inventory`)
        .once("value")
        .then((snapshot) => {
          setInv(snapshot.val());
        });
    }
  }, [currentUser, refetchInv, setRefetchInv, searchTerm]);

  React.useEffect(() => {
    if (open) {
      firebase.analytics().logEvent("open_login_modal");
    }
  }, [open]);

  React.useEffect(() => {
    if (showOwned) {
      setSubset(data.filter((d) => !!inv?.[d.id]));
    } else {
      setSubset(data.filter((d, i) => i < page * PAGE_SIZE));
    }
  }, [page, setSubset, data, showOwned, inv]);

  React.useEffect(() => {
    if (!searchTerm && selectedOptions.length < 1) {
      setSubset(data.filter((_, i) => i < page * PAGE_SIZE));
    } else {
      const matchingTitles = data.filter((d) =>
        d.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      const matchingTags = data.filter((d) =>
        d.tags.toLowerCase().includes(searchTerm.toLowerCase())
      );
      const titlesAndTagsMatched = Array.from(
        new Set([].concat(...matchingTitles, ...matchingTags))
      );
      if (selectedOptions.length > 0) {
        setSubset(
          titlesAndTagsMatched
            .filter((d) => selectedOptions.includes(d.type))
            .filter((_, i) => i < page * PAGE_SIZE)
        );
      } else {
        setSubset(titlesAndTagsMatched.filter((_, i) => i < page * PAGE_SIZE));
      }
    }
  }, [selectedOptions, searchTerm, setSubset, data, page]);
  return (
    <>
      <div
        className={classNames(
          isDarkMode ? "darkMode" : "normalMode",
          isMobile ? "noPadding" : "padding"
        )}
      >
        <Modal
          size="mini"
          closeIcon
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        >
          <Modal.Header>Sign in to track.</Modal.Header>
          <Modal.Content>
            <Login
              firebase={firebase}
              setOpen={setOpen}
              auth={firebase.auth()}
            />
          </Modal.Content>
        </Modal>
        {!isMobile && (
          <Navbar
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            options={options}
            setSearchTerm={setSearchTerm}
            isDarkMode={isDarkMode}
            setIsDarkMode={setIsDarkMode}
            currentUser={currentUser}
            auth={firebase.auth()}
            openLoginModal={() => setOpen(true)}
            signOut={() => setCurrentUser(null)}
            setShowOwned={setShowOwned}
          />
        )}
        {showMobileMenu && (
          <div>
            <div>
              <Segment inverted={isDarkMode}>
                <Logo />
                <Divider />
                <Form inverted={isDarkMode}>
                  <Form.Group>
                    <label>Show only my threads</label>
                    <Form.Field
                      toggle
                      control={Radio}
                      onChange={() => setShowOwned(!showOwned)}
                      checked={showOwned}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label>Dark Mode</label>
                    <Form.Field
                      toggle
                      control={Radio}
                      onChange={() => setIsDarkMode(!isDarkMode)}
                      checked={isDarkMode}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Field
                      label="Search:"
                      control={Input}
                      value={localSearch}
                      onChange={(e) => setLocalSearch(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setSearchTerm(localSearch);
                        }
                      }}
                      icon="search"
                      placeholder="Search..."
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Field
                      label="Filter by type:"
                      control={Dropdown}
                      clearable
                      placeholder="Thread Type"
                      multiple
                      search
                      selection
                      onChange={(e, { value }) => setSelectedOptions(value)}
                      value={selectedOptions}
                      options={options}
                    />
                  </Form.Group>
                  <Form.Group>
                    {currentUser ? (
                      <Form.Field
                        label="Signed in as:"
                        control={Dropdown}
                        text={currentUser.email}
                        floating
                        labeled
                      >
                        <Dropdown.Menu>
                          <Dropdown.Header>
                            <Button
                              fluid
                              color="orange"
                              onClick={() => {
                                setCurrentUser(null);
                                firebase.auth().signOut();
                              }}
                            >
                              Logout
                            </Button>
                          </Dropdown.Header>
                        </Dropdown.Menu>
                      </Form.Field>
                    ) : (
                      <Button onClick={() => setOpen(true)}>
                        Sign in to start tracking
                      </Button>
                    )}
                  </Form.Group>
                </Form>
                <Divider />
                <Button
                  onClick={() => setShowMobileMenu(false)}
                  inverted={isDarkMode}
                  fluid
                  size="huge"
                  color="olive"
                >
                  Close menu
                </Button>
              </Segment>
            </div>
          </div>
        )}
        <Header inverted={!!isDarkMode} style={{ padding: "20px" }}>
          {showOwned ? "My Threads" : "All Threads"}
        </Header>
        <MainTable
          selectedOptions={selectedOptions}
          searchTerm={searchTerm}
          isDarkMode={isDarkMode}
          setRefetchInv={setRefetchInv}
          setOpen={setOpen}
          userInv={inv}
          currentUser={currentUser}
          firebase={firebase}
          data={subset}
          page={page}
          setPage={setPage}
        />
      </div>
      {isMobile && (
        <Button
          circular
          primary
          size="massive"
          onClick={() => setShowMobileMenu(!showMobileMenu)}
          style={{ position: "fixed", bottom: 10, right: 10 }}
          icon={showMobileMenu ? "close" : "bars"}
        />
      )}
    </>
  );
}

export default App;
