import React from "react";
import {
  Icon,
  Button,
  Container,
  Divider,
  Message,
  Modal,
  Form,
} from "semantic-ui-react";

function Login({ setOpen, auth, firebase }) {
  const [signIn, setSignIn] = React.useState(true);
  const [pw, setPw] = React.useState("");
  const [cpw, setCpw] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [resetPassword, setResetPassword] = React.useState(false);

  function submitReset() {
    setIsLoading(true);
    setError(null);
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setIsLoading(false);
      })
      .catch((e) => {
        setError(e.message);
        setIsLoading(false);
      });
  }

  function submitForm() {
    setIsLoading(true);
    setError(null);
    if (!signIn) {
      if (pw === cpw) {
        auth
          .createUserWithEmailAndPassword(email, pw)
          .then((res) => {
            firebase.database().ref(`/users/${res.user.uid}`).set({
              email: res.user.email,
            });
            setIsLoading(false);
            setOpen(false);
          })
          .catch((e) => {
            setIsLoading(false);
            setError(e.message);
          });
      } else {
        setIsLoading(false);
        setError("Your passwords need to match");
      }
    } else {
      auth
        .signInWithEmailAndPassword(email, pw)
        .then((res) => {
          setIsLoading(false);
          setOpen(false);
        })
        .catch((e) => {
          setIsLoading(false);
          setError(e.message);
        });
    }
  }

  return resetPassword ? (
    <Form error={!!error}>
      {error && (
        <Message error header="Something went wrong." content={error} />
      )}
      <Form.Field>
        <label>Email Address</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email address to reset your password"
        />
        <Divider />
        <Button fluid loading={isLoading} color="green" onClick={submitReset}>
          <Icon name="checkmark" /> Reset Password
        </Button>
      </Form.Field>
    </Form>
  ) : (
    <Form error={!!error}>
      {error && (
        <Message error header="Something went wrong." content={error} />
      )}
      <Form.Field>
        <label>Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email address"
        />
      </Form.Field>
      <Form.Field>
        <label>Password</label>
        <input
          type="password"
          value={pw}
          onChange={(e) => setPw(e.target.value)}
          placeholder="Enter a password"
        />
      </Form.Field>
      {!signIn && (
        <Form.Field>
          <label>Confirm Password</label>
          <input
            type="password"
            value={cpw}
            onChange={(e) => setCpw(e.target.value)}
            placeholder="Confirm password"
          />
        </Form.Field>
      )}
      <Modal.Actions>
        <Container textAlign="center">
          <Button.Group fluid>
            <Button loading={isLoading} color="green" onClick={submitForm}>
              <Icon name="checkmark" /> {signIn ? "Sign In" : "Create"}
            </Button>
            <Button loading={isLoading} onClick={() => setOpen(false)}>
              <Icon name="remove" /> Cancel
            </Button>
          </Button.Group>
        </Container>
        <Container textAlign="left">
          <Divider horizontal>Or</Divider>
          <Button fluid loading={isLoading} onClick={() => setSignIn(!signIn)}>
            {signIn ? "Create new Account" : "Login to Existing Account"}
          </Button>
          <Divider horizontal />
          <Button
            fluid
            loading={isLoading}
            onClick={() => setResetPassword(true)}
          >
            Reset password
          </Button>
        </Container>
      </Modal.Actions>
    </Form>
  );
}

export default Login;
