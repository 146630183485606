import React from "react";
import { Header, Divider, Button, Form } from "semantic-ui-react";
import "../index.css";

function InventoryForm({
  setRefetchInv,
  userInv,
  currentUser,
  firebase,
  thread,
  isDarkMode,
}) {
  const [inventory, setInventory] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (loading) {
      setTimeout(() => setLoading(false), 1000);
    }
  }, [loading, setLoading]);

  React.useEffect(() => {
    if (userInv?.sizes) {
      setInventory(userInv.sizes);
    } else {
      const blankInv = {};
      thread.sizes && thread.sizes.forEach(({ size }) => (blankInv[size] = ""));
      setInventory(blankInv);
    }
  }, [thread.sizes, userInv]);

  function saveInventory() {
    setRefetchInv(true);
    for (let key in inventory) {
      if (inventory[key] === "") delete inventory[key];
    }
    if (currentUser)
      firebase
        .database()
        .ref(`/users/${currentUser.uid}/inventory/${thread.id}`)
        .update({
          sizes: inventory,
        });
  }

  return (
    <Form inverted={isDarkMode} size="small">
      <Divider />
      {thread.sizes && (
        <>
          <Header style={{ color: isDarkMode ? "white" : "black" }}>
            {thread.sizes?.length <= 1 ? "" : "Sizes"}
          </Header>
          {thread.sizes?.map(({ size, url }) => {
            if (size.size) {
              size = size.size;
            }
            return (
              <span key={size}>
                <Form.Input
                  key={size}
                  value={inventory[size]}
                  onChange={(e) => {
                    setInventory({ ...inventory, [size]: e.target.value });
                  }}
                  placeholder="Add inventory"
                  label={size === 0 ? "How many do you own?" : size}
                >
                  <input type="number" pattern="[0-9]*" />
                </Form.Input>
                <Button
                  style={{ marginTop: "5px" }}
                  as="a"
                  color="green"
                  href={url}
                >
                  Order more
                </Button>
                <Divider />
              </span>
            );
          })}
        </>
      )}
      <div>
        <Button
          onClick={() => {
            setLoading(true);
            saveInventory();
          }}
          style={{ marginTop: "5px" }}
          color="blue"
        >
          {loading ? "Saved!" : "Save inventory"}
        </Button>
      </div>
    </Form>
  );
}

export default InventoryForm;
